import { useTheme } from '@emotion/react';
import { useMediaQuery } from '@mui/material';
import { Stack } from '@mui/system';

import {
  TabsProvider,
  TabList,
  TabButton,
  TabPanel,
  Wrapper,
} from '../../primitives';
import { SliceContainer } from '../SliceContainer';

type TabSliceProps = {
  slice: {
    children: {
      title: string;
      children: any[];
    }[];
  };
};

export const TabsSlice = ({ slice }: TabSliceProps) => {
  const theme = useTheme();
  const showVertically = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <TabsProvider value="0" indicatorVariant="large">
      <Wrapper marginTop={5} marginBottom={3}>
        <Stack
          sx={{
            alignItems: 'center',
            width: '100%',
          }}
        >
          <TabList orientation={showVertically ? 'vertical' : 'horizontal'}>
            {slice.children.map((tab, index) => (
              <TabButton label={tab.title} value={String(index)} key={index} />
            ))}
          </TabList>
        </Stack>
      </Wrapper>

      {slice.children.map((tab, index) => (
        <TabPanel value={String(index)} key={index}>
          <SliceContainer slices={tab.children} />
        </TabPanel>
      ))}
    </TabsProvider>
  );
};
